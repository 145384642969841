import { LitElement, html, css } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { ValidationController } from 'controllers/validation-controller';
import { z } from 'zod';
import { PliInputChangeEvent } from 'pli/controllers/input-controller';
import { when } from 'lit/directives/when.js';
import { PliInputState } from 'pli/types/input-state';
import { PliCheckboxUpdateEvent } from '../../../pli/pli-checkbox';
import { ReportFilingTransferType } from 'tms-client';

import '../../../pli/pli-dialog';
import '../../../pli/pli-input';
import '../../../pli/pli-alert-box';
import '../../../pli/pli-checkbox';

const schema = z.object({
    reportName: z.string().min(1, 'Report name is required'),
});

@customElement('report-case-dialog')
export class ReportCaseDialog extends LitElement {
    static styles = [
        css`
            :host {
                display: block;
            }

            pli-checkbox {
                display: block;
                background-color: var(--body-bg);
                border-radius: var(--radius-md);
            }
        `,
    ];

    @property({ type: Number })
    documentCount: number;

    @state()
    reportName = '';
    @state()
    includeAttachedDocuments = true;

    schema = schema;
    validationController = new ValidationController(this, this.schema);

    onChange(event: PliInputChangeEvent) {
        const { value } = event.detail;
        this.reportName = value;
        this.validationController.parse({ reportName: this.reportName });
    }

    onPrimaryDismiss() {
        const { isValid } = this.validationController.parse({ reportName: this.reportName });

        if (!isValid) {
            return;
        }

        const transferTypes: ReportFilingTransferType[] = this.hasDocuments ? ['AttachedDocuments'] : [];

        const _event: ReportCaseEvent = new CustomEvent('report', {
            composed: true,
            detail: { reportName: this.reportName, transferTypes },
        });
        this.dispatchEvent(_event);
    }

    onAttachedDocumentsChange(event: PliCheckboxUpdateEvent) {
        const { checked } = event.detail;
        this.includeAttachedDocuments = checked;
    }

    get issues() {
        return this.validationController.issues;
    }

    get hasIssues() {
        return this.validationController.issues.length > 0;
    }

    get hasDocuments() {
        return this.documentCount > 0;
    }

    render() {
        const inputState: PliInputState = this.hasIssues ? 'invalid' : 'valid';

        return html`<pli-dialog @primary-dismiss="${this.onPrimaryDismiss}">
            <pli-dialog-open-button slot="open-button">Report case</pli-dialog-open-button>
            <pli-dialog-content>
                <pli-icon-box name="envelope-paper"></pli-icon-box>
                <pli-text variant="h3">Report Case</pli-text>
                <pli-text as="p"
                    >Pre-fill information from case. You can pre-fill boxes to speed up your workflow.
                </pli-text>
                <pli-input state="${inputState}" value="${this.reportName}" @change="${this.onChange}"></pli-input>
                ${when(
                    this.hasIssues,
                    () => html`<pli-alert-box variant="error">${this.issues[0].message}</pli-alert-box>`,
                )}
                ${when(
                    this.hasDocuments,
                    () =>
                        html` <pli-checkbox
                            .checked="${this.includeAttachedDocuments}"
                            value="${this.includeAttachedDocuments}"
                            @change="${this.onAttachedDocumentsChange}"
                            ><div class="grid-vertical gap-05">
                                <pli-text as="p">Attached documents</pli-text>
                                <pli-text>All (${this.documentCount}) documents attached to the case.</pli-text>
                            </div></pli-checkbox
                        >`,
                )}
            </pli-dialog-content>
            <pli-dialog-close-button slot="secondary-button">Cancel</pli-dialog-close-button>
            <pli-dialog-primary-button ?disabled="${this.hasIssues || !this.reportName.length}" slot="primary-button"
                >Report case</pli-dialog-primary-button
            >
        </pli-dialog>`;
    }
}

export type ReportCaseEvent = CustomEvent<{ reportName: string; transferTypes: ReportFilingTransferType[] }>;
