import { LitElement, TemplateResult, css, html } from 'lit';
import { customElement, state, property } from 'lit/decorators.js';
import 'pli/pli-card';
import 'pli/pli-text';
import 'pli/pli-button';
import 'pli/pli-user-bubble';
import 'pli/pli-drag-drop';
import 'pli/pli-dialog';

import { styles } from 'pli/styles';
import { defineHeaderItems } from 'pli/pli-table';
import { dateTimeFormatter } from 'utils/datetime-formatter';
import { nameFormatter } from 'utils/name-formatter';
import { downloadContent } from 'utils/download-content';
import { consume } from '@lit/context';
import { Task } from '@lit/task';
import { FileUpdateEvent } from 'pli/pli-drag-drop';
import { UserModel } from 'user-model';
import { CurrentUser, currentUserContext } from 'context/current-user-context';
import { when } from 'lit/directives/when.js';
import {
    archiveCaseDocument,
    CaseDocument,
    downloadCaseDocument,
    getCaseDocumentList,
    ListResultOfItemResult13,
    uploadCaseDocument,
} from 'tms-client';
import { errorDialogContext, ErrorDialogController } from 'context/error-dialog-context';

@customElement('case-details-documents')
class CaseDetailsDocuments extends LitElement {
    static styles = [styles.grid, styles.flex, styles.divider, styles.padding, css``];

    @property({ type: String, reflect: true })
    caseId?: string;

    @property({ type: Boolean, reflect: true })
    disabled?: boolean;

    @consume({ context: currentUserContext })
    currentUser?: CurrentUser;

    @consume({ context: errorDialogContext })
    errorDialog?: ErrorDialogController;

    @state()
    _data: ListResultOfItemResult13 | null = null;

    _task = new Task(this, {
        task: async ([caseId]) => {
            const { data, error } = await getCaseDocumentList({ path: { id: Number(caseId) } });

            if (error) {
                return this.errorDialog.showError({ title: 'Could not get documents for case' });
            }

            this._data = data;
        },
        args: () => [this.caseId] as const,
        autoRun: false,
    });

    _profile = new UserModel();

    async connectedCallback() {
        super.connectedCallback();
        this._task.run();

        if (!this.currentUser) {
            return;
        }

        this._profile = await this.currentUser.get();
    }

    downloadDocument = async (document: CaseDocument) => {
        const { data, error } = await downloadCaseDocument({
            path: { id: Number(this.caseId), documentId: document.documentId },
        });

        if (error) {
            return this.errorDialog.showError({ title: 'Could not download document' });
        }

        downloadContent(data, document.name);
    };

    onFilesUpdate = async (event: FileUpdateEvent) => {
        const { value } = event.detail;
        for (const file of value) {
            const { error } = await uploadCaseDocument({
                path: { id: Number(this.caseId) },
                body: {
                    file,
                },
            });

            if (error) {
                return this.errorDialog.showError({ title: `Upload error for file: ${file.name}` });
            }

            this._task.run();
        }

        this.emit();
    };

    onFilesFail = (event: FileUpdateEvent) => {
        const { maximumFileSize } = event.detail;
        this.errorDialog.showError({
            title: 'File size limit reached',
            errorMessage: `Attached file(s) exceeds our file size limit (${maximumFileSize} MB), please try to reduce the size of your file or try a different file. `,
        });
    };

    private emit() {
        this.dispatchEvent(new CustomEvent('files-update'));
    }

    _renderRemoveDocumentDialog = (item: CaseDocument) => {
        const handleRemoveDocument = async (document: CaseDocument) => {
            const { error } = await archiveCaseDocument({
                path: { id: Number(this.caseId), documentId: document.documentId },
                parseAs: 'stream',
            });

            if (error) {
                return this.errorDialog?.showError({ title: 'Could not remove document' });
            }

            this._task.run();
        };

        return html`
            <pli-dialog>
                <pli-dialog-open-button
                    class="flex justify-end items-center"
                    slot="open-button"
                    variant="destructive"
                    width="hug-content"
                    ><pli-icon name="trash" slot="icon-left"></pli-icon>Remove</pli-dialog-open-button
                >

                <pli-dialog-content
                    ><pli-icon-box slot="icon" name="question-circle" color="blue"></pli-icon-box>
                    <pli-text variant="h3">Deleting document</pli-text>
                    <p>Are you sure you want to delete the file: ${item.name}? This action cannot be undone.</p>
                </pli-dialog-content>

                <pli-dialog-close-button slot="secondary-button">Cancel</pli-dialog-close-button>
                <pli-dialog-primary-button
                    @pli-dialog-primary-dismiss="${() => handleRemoveDocument(item)}"
                    variant="destructive"
                    slot="primary-button"
                    >Remove</pli-dialog-primary-button
                >
            </pli-dialog>
        `;
    };

    render() {
        const { downloadDocument, _data, onFilesUpdate, onFilesFail } = this;
        const hasItems = Boolean(_data?.list.length);
        const headerItems = defineHeaderItems({
            Name: {
                sortField: 'Name',
                columnSpan: 3,
            },
            Date: {
                sortField: null,
                columnSpan: 3,
            },
            'Created by': {
                sortField: null,
                columnSpan: 3,
            },
            '': {
                sortField: null,
                columnSpan: 3,
            },
        });

        const renderItem = (item: CaseDocument): TemplateResult =>
            html` <tr>
                <td>
                    <pli-button variant="text" .onClick="${() => downloadDocument(item)}">
                        <pli-icon slot="icon-left" name="file-earmark-image"></pli-icon>
                        ${item.name}
                    </pli-button>
                </td>
                <td>${dateTimeFormatter(item.uploadedTime.toString())}</td>
                <td>
                    <div class="flex items-center gap-1">
                        <div>
                            <pli-user-bubble
                                userId="${item.uploadedBy.userId}"
                                firstName="${item.uploadedBy.firstName}"
                                lastName="${item.uploadedBy.lastName}"
                            ></pli-user-bubble>
                        </div>
                        <pli-text
                            >${nameFormatter({
                                firstName: item.uploadedBy.firstName,
                                lastName: item.uploadedBy.lastName,
                                userId: item.uploadedBy.userId,
                            })}</pli-text
                        >
                    </div>
                </td>
                <td>${when(!this.disabled, () => this._renderRemoveDocumentDialog(item))}</td>
            </tr>`;

        return html`<pli-card>
            <div class="grid-vertical gap-1">
                <div class="grid-vertical gap-1 divider-y">
                    <div class="grid-vertical gap-1">
                        <pli-text as="h4" variant="h4">Documents</pli-text>
                        <pli-text as="p">
                            ${this.disabled
                                ? html`Attached files for your investigation`
                                : html`Attach, view and download any file you need to document your investigation`}
                        </pli-text>
                    </div>
                    ${hasItems
                        ? html`
                              <pli-table
                                  .headerItems="${headerItems}"
                                  .items="${_data?.list ?? []}"
                                  .renderTemplate="${renderItem}"
                              ></pli-table>
                          `
                        : html`<div class="py-4">
                              <div class="grid">
                                  <div class="col-span-6 col-start-4 grid-vertical gap-05">
                                      ${when(
                                          !this.disabled,
                                          () =>
                                              html`<pli-text as="p" variant="h4">No files or documents yet</pli-text>
                                                  <pli-text as="p"
                                                      >However, its very easy to add new ones (Maximum file size: 10 MB)
                                                  </pli-text>`,
                                      )}
                                  </div>
                              </div>
                          </div>`}
                </div>
                ${when(
                    !this.disabled,
                    () =>
                        html`<pli-drag-drop
                            @filesUpdate="${onFilesUpdate}"
                            @filesFail="${onFilesFail}"
                        ></pli-drag-drop>`,
                )}
            </div>
        </pli-card>`;
    }
}
