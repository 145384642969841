import { LitElement, html, css, TemplateResult } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '../../../pli/pli-dialog';
import '../../../pli/pli-pager';
import '../../../pli/pli-table';
import { Task } from '@lit/task';
import { defineHeaderItems } from '../../../pli/pli-table';
import {
    GetCaseAlertAttachedList_ItemResult,
    getCaseAlertNotAttachedList,
    GetCaseAlertNotAttachedList_ItemResult,
    GetCaseAlertNotAttachedList_SortField,
    GetCaseAlertNotAttachedListResponse,
} from 'tms-client';
import { dateTimeFormatter } from 'utils/datetime-formatter';
import '../../../pli/pli-status-label';
import '../../../pli/pli-checkbox';
import { consume } from '@lit/context';
import { errorDialogContext, ErrorDialogController } from 'context/error-dialog-context';
import { PliCheckboxUpdateEvent } from '../../../pli/pli-checkbox';
import { PliPagerUpdatedEvent } from '../../../pli/pli-pager';

@customElement('add-alert-to-case-dialog')
export class AddAlertToCaseDialog extends LitElement {
    @property()
    customerId: string;

    @property()
    caseId: string;

    @property({ type: Boolean })
    disabled: boolean = false;

    @state()
    selected: GetCaseAlertAttachedList_ItemResult['alertId'][] = [];

    @state()
    page = 1;

    @state()
    sortField: GetCaseAlertNotAttachedList_SortField = 'Created';

    data: GetCaseAlertNotAttachedListResponse = null;

    take = 7;

    @consume({ context: errorDialogContext })
    errorDialog: ErrorDialogController;

    static styles = [
        css`
            :host {
                display: block;
            }
        `,
    ];

    _task = new Task(this, {
        task: async ([caseId, sortField, page]) => {
            const { error, data } = await getCaseAlertNotAttachedList({
                path: { id: Number(caseId) },
                query: { sortField, page, take: this.take },
            });
            if (error) {
                return this.errorDialog.showError({ title: 'Could not get alerts' });
            }

            this.data = data;
        },
        args: () => [this.caseId, this.sortField, this.page] as const,
    });

    _headerItems = defineHeaderItems({
        ['']: {
            sortField: null,
            columnSpan: 1,
        },
        Id: {
            sortField: null,
            columnSpan: 1,
        },
        Rule: {
            sortField: null,
            columnSpan: 1,
        },
        Status: {
            sortField: null,
            columnSpan: 2,
        },
        Created: {
            sortField: null,
            columnSpan: 4,
        },
    });

    onChange(event: PliCheckboxUpdateEvent) {
        const { checked, value } = event.detail;

        if (checked) {
            this.selected = [...this.selected, Number(value)];
        } else {
            this.selected = [...this.selected.filter((i) => i !== Number(value))];
        }
    }

    // Workaround since our pli-checkbox cannot span all columns because TABLES
    onTableCellClick(alert: GetCaseAlertNotAttachedList_ItemResult) {
        if (this.selected.includes(alert.alertId)) {
            this.selected = [...this.selected.filter((i) => i !== Number(alert.alertId))];
        } else {
            this.selected = [...this.selected, alert.alertId];
        }
    }

    onPageUpdate(event: PliPagerUpdatedEvent) {
        const { page, skip } = event.detail;

        this.page = page;
    }

    handlePrimaryDismiss() {
        this.dispatchEvent(new CustomEvent('add-alerts', { composed: true, detail: { value: this.selected } }));
        this.selected = [];
    }

    render() {
        if (!this.data) {
            return null;
        }

        const renderItem = (alert: GetCaseAlertNotAttachedList_ItemResult): TemplateResult => html`
            <tr>
                <td>
                    <pli-checkbox
                        .value="${alert.alertId.toString()}"
                        .checked="${this.selected.includes(alert.alertId)}"
                        @change="${(event: PliCheckboxUpdateEvent) => this.onChange(event)}"
                    ></pli-checkbox>
                </td>
                <td @click="${() => this.onTableCellClick(alert)}">${alert.alertId}</td>
                <td @click="${() => this.onTableCellClick(alert)}">${alert.rule.name}</td>
                <td @click="${() => this.onTableCellClick(alert)}">
                    <pli-status-label variant="${alert.status}"></pli-status-label>
                </td>
                <td @click="${() => this.onTableCellClick(alert)}">${dateTimeFormatter(alert.created)}</td>
            </tr>
        `;

        return html`<pli-dialog modalWidth="md" @primary-dismiss="${this.handlePrimaryDismiss}">
            <pli-dialog-open-button slot="open-button" iconStart="plus" iconSize="${24}"
                >Add new Alert</pli-dialog-open-button
            >
            <pli-dialog-content>
                <pli-text variant="h3">Add alerts to this case</pli-text>
                <pli-text>Add the suspicious alerts related to the case.</pli-text>
                <pli-pager .items="${this.data.list}" total="${this.data.total}" @page-update="${this.onPageUpdate}">
                    <pli-table
                        .headerItems="${this._headerItems}"
                        .items="${this.data.list}"
                        .renderTemplate="${renderItem}"
                    >
                    </pli-table>
                </pli-pager>
            </pli-dialog-content>
            <pli-dialog-close-button slot="secondary-button">Cancel</pli-dialog-close-button>
            <pli-dialog-primary-button slot="primary-button">Confirm</pli-dialog-primary-button>
        </pli-dialog>`;
    }
}

export type AddAlertToCaseEvent = CustomEvent<{ value: number[] }>;
