import { UserModel } from 'user-model';
import { CommentModel } from 'comment-model';
import { AlertModel } from 'alert-model';
import { DocumentModel } from 'document-model';

export class CaseModel {
    caseId: string;
    name: string;
    status: Status;
    customer: Customer = new Customer();
    created: Date = new Date();
    assigned: UserModel[] = [];
    createdBy: UserModel = new UserModel();
    closedComment: string;
    comment: string;
    comments: CommentModel[] = [];
    narrative: CommentModel[] = [];
    documents: DocumentModel[] = [];
    auditTrail: AuditTrailItemModel[] = [];
    description: string;
    deadline: string;
    customerId: string;
    alerts: AlertModel[] = [];
    closed: boolean;
    reportFilingId?: number = undefined;
    reportFilingName: string;
}

class Customer {
    customerId: string;
}

class AuditTrailItemModel {
    dateTime: string;
    user: UserModel;
    action: string;
    description: string;
}

export enum Status {
    Investigating = 'Investigating',
    Resolved = 'Resolved',
    Reporting = 'Reporting',
    Reported = 'Reported',
}
