import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../../pli/pli-dialog';
import { CaseStatus } from 'tms-client';
import { when } from 'lit/directives/when.js';
import '../../../pli/pli-status-label';

@customElement('open-case-dialog')
export class OpenCaseDialog extends LitElement {
    static styles = [
        css`
            :host {
                display: block;
            }
        `,
    ];

    @property()
    reportName: string;

    @property()
    reportFilingId: string;

    @property()
    caseStatus: CaseStatus;

    onPrimaryDismiss() {
        this.dispatchEvent(new CustomEvent('open-case', { composed: true }));
    }

    get caseIsReported() {
        return this.caseStatus === 'Reported';
    }

    get caseIsReporting() {
        return this.caseStatus === 'Reporting';
    }

    get caseIsIncludedInReport() {
        return Boolean(this.caseIsReported || this.caseIsReporting);
    }

    render() {
        return html`<pli-dialog @primary-dismiss="${this.onPrimaryDismiss}">
            <pli-dialog-open-button slot="open-button">Open case</pli-dialog-open-button>
            <pli-dialog-content>
                <pli-icon-box name="arrow-counterclockwise" slot="icon"></pli-icon-box>
                ${when(
                    this.caseIsIncludedInReport,
                    () => html`
                        <pli-text as="p">Before reopening the case the linked report needs to be deleted: </pli-text>
                        <pli-button variant="text" as="a" href="report-filing/${this.reportFilingId}"
                            >${this.reportName} -></pli-button
                        >
                    `,
                    () => html`
                        <pli-text variant="h3" as="p">This case is closed</pli-text>
                        <pli-text as="p"
                            >This case has been resolved by an agent. You cannot make any changes to this case until you
                            have re-opened it using the open button.
                        </pli-text>
                        <pli-text as="p"><strong>Closed as</strong></pli-text>
                        <pli-text as="p"><pli-status-label variant="${this.caseStatus}"></pli-status-label></pli-text>
                    `,
                )}
            </pli-dialog-content>
            ${when(
                this.caseIsIncludedInReport,
                () => html`<pli-dialog-close-button slot="secondary-button">Ok</pli-dialog-close-button>`,
                () => html`
                    <pli-dialog-close-button slot="secondary-button">Cancel</pli-dialog-close-button>
                    <pli-dialog-primary-button slot="primary-button">Open</pli-dialog-primary-button>
                `,
            )}
        </pli-dialog>`;
    }
}
