import { LitElement, TemplateResult, css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

import 'pli/pli-card';
import 'pli/pli-text';
import 'pli/pli-status-label';
import 'pli/pli-user-bubble-list';
import 'pli/pli-input';
import 'pli/pli-button';
import 'pli/pli-status-label';
import 'pli/pli-textarea';
import 'pli/pli-alert-box';

import './components/case-details-comments';
import './components/case-details-alerts';
import './components/case-details-documents';
import 'shared/transaction/transaction-history-area';

import db from 'just-debounce';
import { Task } from '@lit/task';
import { styles } from 'pli/styles';
import { consume } from '@lit/context';
import { PliInputChangeEvent } from 'pli/controllers/input-controller';
import { PliUserBubbleListProps } from '../../pli/pli-user-bubble-list';
import { dateTimeFormatter } from 'utils/datetime-formatter';
import { DialogService, dialogServiceContext } from 'context/dialog-service-context';
import { CurrentUser, currentUserContext } from 'context/current-user-context';
import { when } from 'lit/directives/when.js';
import { Router, routerContext } from 'context/router-context';
import { CaseStatus } from 'domain/status/case-status';
import { Issue, ValidationController } from 'controllers/validation-controller';
import { mainSchema, Payload, SchemaKey } from './schema';
import { PliInputProps } from 'pli/pli-input';
import { customerDetailsContext } from 'context/details/customer-details-context';
import {
    assignCaseUser,
    closeCase,
    createReportFiling,
    getCase,
    GetCase_Result,
    GetCustomer_Result,
    getUserList,
    openCase,
    updateCase,
    User,
} from 'tms-client';
import { errorDialogContext, ErrorDialogController } from 'context/error-dialog-context';
import './components/report-case-dialog';
import { ReportCaseEvent } from './components/report-case-dialog';
import { toastServiceContext } from 'context/toast-service-context';
import { ToastController } from 'controllers/toast-controller';
import './components/open-case-dialog';
import '../../components/dialog/assign-dialog';
import { AssignDialogEvent } from '../../components/dialog/assign-dialog';

@customElement('cases-details')
class CasesDetails extends LitElement {
    @property({ type: String, reflect: true })
    caseId?: string;

    @consume({ context: routerContext })
    _router?: Router;

    @consume({ context: customerDetailsContext, subscribe: true })
    customerDetails: GetCustomer_Result | null = null;

    @state()
    _caseId: string | null = null;

    @state()
    _documents: GetCase_Result['documents'] = [];

    @state()
    _assigned: User[] = [];

    _users: User[] = [];

    _issues: Issue[];

    _caseModel: GetCase_Result = null;

    static styles = [
        styles.base,
        styles.grid,
        styles.flex,
        styles.form,
        styles.position,
        css`
            :host {
                --button-group-top: var(--size-2-5);
            }
            .button-group {
                top: var(--button-group-top);
                align-self: flex-start;
            }
        `,
    ];

    @consume({ context: dialogServiceContext })
    dialogService?: DialogService;

    @consume({ context: errorDialogContext })
    errorDialog?: ErrorDialogController;

    @consume({ context: currentUserContext })
    currentUser?: CurrentUser;

    @consume({ context: toastServiceContext })
    toastService: ToastController;

    _validationController = new ValidationController(this, mainSchema);

    connectedCallback(): void {
        super.connectedCallback();
        this._caseId = this.caseId ?? null;
    }

    _task = new Task(this, {
        task: async ([caseId]) => {
            await Promise.all([this.loadCase(caseId), this.loadUsers()]);
        },
        args: () => [this._caseId] as const,
    });

    private async loadCase(caseId: string) {
        const { data, error } = await getCase({ path: { id: Number(caseId) } });

        if (error) {
            return this.errorDialog.showError({ title: 'Could not get case' });
        }

        this._caseModel = data;
        this._documents = this._caseModel.documents;
        this._assigned = this._caseModel.assigned;
    }

    private async loadUsers() {
        const { data, error } = await getUserList();

        if (error) {
            return this.errorDialog.showError({ title: 'Could not load users' });
        }

        this._users = data.list;
    }

    onChange = () => {
        this._saveWithDebounce();
    };

    onChangeWithValidation = (payload: Payload) => {
        const { isValid } = this._validationController.parse(payload);
        this._issues = [...this._validationController.issues];

        if (!isValid) {
            return;
        }

        this._caseModel.name = payload.name;
        this._saveWithDebounce();
    };

    _saveWithDebounce = db(async () => {
        const { error } = await updateCase({
            path: { id: Number(this.caseId) },
            body: {
                name: this._caseModel.name,
                description: this._caseModel.description,
                comment: this._caseModel.comment,
            },
        });
        if (error) {
            this.errorDialog.showError({ title: 'Could not save case' });
        }
    }, 1000);

    get disabled(): boolean {
        return this._caseModel.closed;
    }

    get hasDocuments() {
        return this._caseModel.documents.length > 0;
    }

    onFilesUpdate() {
        this._task.run();
    }

    async onReport(event: ReportCaseEvent) {
        const { reportName, transferTypes } = event.detail;

        const { data, error } = await createReportFiling({
            body: { caseId: this._caseModel.caseId, filingName: reportName, transferTypes },
        });

        if (error) {
            return this.errorDialog.showError({ title: 'Could not create report' });
        }

        const { reportId } = data;
        this._router.navigate(`report-filing/${reportId}`);
    }

    async onOpenCase() {
        const { error } = await openCase({ path: { id: this._caseModel.caseId } });

        if (error) {
            return this.errorDialog.showError({ title: 'Could not open case' });
        }

        await this._task.run();
    }

    async onAssign(event: AssignDialogEvent) {
        const { users } = event.detail;
        const userIds = users.map((i) => i.userId);

        const { error } = await assignCaseUser({
            path: { id: Number(this.caseId) },
            body: { userIds },
        });

        if (error) {
            return this.errorDialog.showError({ title: 'Could not assign user / users to case' });
        }

        this._assigned = [...users];
    }

    getStateForField = (key: SchemaKey): PliInputProps['state'] => {
        const issue = this._issues?.find((issue) => issue.name === key);
        return Boolean(issue) ? 'invalid' : 'initial';
    };

    renderStatusLabel(): TemplateResult {
        if (this._caseModel.status) {
            return html`
                <pli-status-label variant="${this._caseModel.status}">${this._caseModel.status}</pli-status-label>
            `;
        }
    }

    renderDate(): TemplateResult {
        if (this._caseModel.created) {
            return html` ${dateTimeFormatter(this._caseModel.created.toString())} `;
        }
    }

    renderAssigned(): TemplateResult {
        if (this._assigned) {
            return html`
                <pli-user-bubble-list
                    .items="${this._assigned as PliUserBubbleListProps['items']}"
                ></pli-user-bubble-list>
            `;
        }
    }

    renderConclusion(): TemplateResult {
        const investigating = html`
            <pli-alert-box variant="info" displayMode="text">
                <pli-icon name="info-circle"></pli-icon>
                <p>A decision has not been made, this case is still under investigation.</p>
            </pli-alert-box>
        `;
        const resolved = html`
            <pli-alert-box variant="success" displayMode="text">
                <pli-icon name="check2-circle"></pli-icon>
                <p>Case has been resolved and is now closed, read Summary to understand conclusion.</p>
            </pli-alert-box>
        `;
        const reporting = html`
            <pli-alert-box variant="success" displayMode="text">
                <pli-icon name="check2-circle"></pli-icon>
                <div>
                    <p>
                        A decision to file a report has been made. The report is being written and ready to be
                        submitted.
                    </p>
                    <pli-button variant="text" as="a" size="lg" href="report-filing/${this._caseModel.reportFilingId}">
                        ${this._caseModel.reportFilingName} ->
                    </pli-button>
                </div>
            </pli-alert-box>
        `;
        const reported = html`
            <pli-alert-box variant="success" displayMode="text">
                <pli-icon name="check2-circle"></pli-icon>
                <div>
                    <p>A decision to file a report has been made. The report is submitted to the goAML portal.</p>
                    <pli-button variant="text" as="a" size="lg" href="report-filing/${this._caseModel.reportFilingId}">
                        ${this._caseModel.reportFilingName} ->
                    </pli-button>
                </div>
            </pli-alert-box>
        `;
        const componentMap: Record<CaseStatus, TemplateResult> = {
            Investigating: investigating,
            Resolved: resolved,
            Reporting: reporting,
            Reported: reported,
        };
        return componentMap[this._caseModel.status];
    }

    renderCloseDialog(): TemplateResult {
        const handlePrimary = async () => {
            const { error } = await closeCase({ path: { id: Number(this._caseId) } });

            if (error) {
                return this.errorDialog.showError({ title: 'Could not close case' });
            }
            this.loadCase(this._caseId);
        };

        return html`
            <pli-dialog>
                <pli-dialog-open-button slot="open-button" variant="destructive">Close case</pli-dialog-open-button>

                <pli-dialog-content
                    ><pli-icon-box slot="icon" name="question-circle" color="blue"></pli-icon-box>
                    <pli-text variant="h3">Close case</pli-text>
                    <p>You are about to close this case and all alerts within this case. Are you sure?</p>
                    <div class="flex items-center justify-center gap-1">
                        <pli-status-label variant="Investigating"></pli-status-label>
                        ->
                        <pli-status-label variant="Resolved"></pli-status-label>
                    </div>
                    <div class="flex justify-center">
                        <pli-text><strong>Change in case status</strong></pli-text>
                    </div></pli-dialog-content
                >

                <pli-dialog-close-button slot="secondary-button">Cancel</pli-dialog-close-button>
                <pli-dialog-primary-button
                    @pli-dialog-primary-dismiss="${handlePrimary}"
                    variant="destructive"
                    slot="primary-button"
                    >Close case</pli-dialog-primary-button
                >
            </pli-dialog>
        `;
    }

    renderCaseIsOpenButtonGroup(): TemplateResult {
        if (this._caseModel.status !== 'Reported' && this._caseModel.status !== 'Reporting') {
            return html`<report-case-dialog
                    .documentCount="${this._caseModel.documents.length}"
                    @report="${this.onReport}"
                ></report-case-dialog>
                <assign-dialog type="case" @assign="${this.onAssign}"></assign-dialog>
                <div>${this.renderCloseDialog()}</div> `;
        } else {
            return html`
                <assign-dialog type="case" @assign="${this.onAssign}"></assign-dialog> ${this.renderCloseDialog()}
            `;
        }
    }

    renderErrorMessage(key: SchemaKey): TemplateResult {
        const issue = this._issues?.find((issue) => issue.name === key);
        if (Boolean(issue)) {
            return html`
                <div class="error">
                    <pli-alert-box variant="error">${issue.message}</pli-alert-box>
                </div>
            `;
        }
    }

    render(): TemplateResult {
        if (!this._caseModel) {
            return null;
        }

        return html`
            <div class="grid-vertical gap-1 relative wrapper">
                <pli-button
                    class="back-button"
                    variant="text"
                    as="a"
                    size="lg"
                    href="customers/${this._caseModel?.customerId}"
                >
                    <pli-icon name="arrow-up-circle" slot="icon-left"></pli-icon>
                    Back to Investigations
                </pli-button>
                <pli-text as="h3" variant="h3">Case ${this._caseModel.name}</pli-text>
                <div class="grid gap-1">
                    <div class="col-span-10">
                        <div class="grid-vertical gap-1">
                            <div class="grid gap-1">
                                <pli-card class="col-span-6">
                                    <form class="grid-vertical gap-1" .inert="${this.disabled}">
                                        <pli-text as="h4" variant="h4">Overview</pli-text>
                                        <div class="grid-vertical gap-05">
                                            <label><strong>Name*</strong></label>
                                            <pli-input
                                                @change="${(event: PliInputChangeEvent) => {
                                                    this.onChangeWithValidation({ name: event.detail.value });
                                                }}"
                                                size="sm"
                                                placeholder="Case name"
                                                value="${this._caseModel.name}"
                                                state="${this.getStateForField('name')}"
                                            ></pli-input>
                                            ${this.renderErrorMessage('name')}
                                        </div>
                                        <div class="grid-vertical gap-05">
                                            <label><strong>Description</strong></label>
                                            <pli-textarea
                                                value="${this._caseModel.description}"
                                                @change="${(event: PliInputChangeEvent) => {
                                                    this._caseModel.description = event.detail.value;
                                                    this.onChange();
                                                }}"
                                                placeholder=""
                                            ></pli-textarea>
                                        </div>
                                        <div class="grid-vertical gap-05">
                                            <label><strong>Status</strong></label>
                                            ${this.renderStatusLabel()}
                                        </div>
                                        <div class="grid-vertical gap-05">
                                            <label><strong>Created</strong></label>
                                            ${this.renderDate()}
                                        </div>
                                        <div class="grid-vertical gap-05">
                                            <label><strong>Assigned</strong></label>
                                            ${this.renderAssigned()}
                                        </div>
                                    </form>
                                </pli-card>
                                <pli-card class="col-span-6">
                                    <div class="grid-vertical gap-3">
                                        <form .inert="${this.disabled}">
                                            <div class="grid-vertical gap-05">
                                                <pli-text as="h4" variant="h4">Summary</pli-text>
                                                <pli-textarea
                                                    value="${this._caseModel.comment}"
                                                    @change="${(event: PliInputChangeEvent) => {
                                                        this._caseModel.comment = event.detail.value;
                                                        this.onChange();
                                                    }}"
                                                    placeholder=""
                                                ></pli-textarea>
                                            </div>
                                        </form>
                                        <div class="grid-vertical">
                                            <pli-text as="h4" variant="h4">Conclusion</pli-text>
                                            ${this.renderConclusion()}
                                        </div>
                                    </div>
                                </pli-card>
                            </div>

                            <case-details-comments
                                caseId="${this._caseId}"
                                .disabled="${this.disabled}"
                            ></case-details-comments>
                            ${when(
                                this._caseModel.customerId,
                                () => html`
                                    <case-details-alerts
                                        caseId="${this._caseId}"
                                        customerId="${this._caseModel.customerId}"
                                        .disabled="${this.disabled}"
                                    ></case-details-alerts>
                                `,
                            )}
                            <case-details-documents
                                caseId="${this._caseId}"
                                .disabled="${this.disabled}"
                                @files-update="${this.onFilesUpdate}"
                            ></case-details-documents>
                            ${when(
                                this._caseModel.customerId,
                                () => html`
                                    <transaction-history-area .customerId="${this._caseModel.customerId}">
                                        <pli-text as="h4" variant="h4" slot="header">Transaction History</pli-text>
                                    </transaction-history-area>
                                `,
                            )}
                        </div>
                    </div>
                    <div class="col-span-2 button-group sticky">
                        <div class="grid-vertical gap-05">
                            ${when(
                                this._caseModel.closed,
                                () =>
                                    html`<open-case-dialog
                                        caseStatus="${this._caseModel.status}"
                                        reportFilingId="${this._caseModel.reportFilingId}"
                                        reportName="${this._caseModel.reportFilingName}"
                                        @open-case="${this.onOpenCase}"
                                    ></open-case-dialog>`,
                                () => this.renderCaseIsOpenButtonGroup(),
                            )}
                        </div>
                    </div>
                </div>
            </div>
        `;
    }
}
