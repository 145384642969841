import { LitElement, TemplateResult, html } from 'lit';
import { customElement, state, property } from 'lit/decorators.js';
import { consume } from '@lit/context';
import { Task } from '@lit/task';

import 'shared/comments/comments-area';

import {
    addCaseComment,
    archiveCaseComment,
    getCaseCommentList,
    GetCaseCommentListResponse,
    updateCaseComment,
} from 'tms-client';
import { errorDialogContext, ErrorDialogController } from 'context/error-dialog-context';

@customElement('case-details-comments')
class CaseDetailsComments extends LitElement {
    @property({ type: String, reflect: true })
    caseId?: string;

    @property({ type: Boolean, reflect: true })
    disabled?: boolean;

    @consume({ context: errorDialogContext })
    errorDialog: ErrorDialogController;

    @state()
    _comments: GetCaseCommentListResponse['list'] = [];

    _task = new Task(this, {
        task: async ([]) => {
            await this.load();
        },
        args: () => [] as const,
    });

    private async load(): Promise<void> {
        const { data, error } = await getCaseCommentList({ path: { id: Number(this.caseId) } });

        if (error) {
            return this.errorDialog.showError({ title: 'Could not get case comments' });
        }

        this._comments = data.list;
    }

    private async onAdd(event: any): Promise<void> {
        const { error } = await addCaseComment({
            path: { id: Number(this.caseId) },
            body: { comment: event.detail.value },
        });

        if (error) {
            return this.errorDialog.showError({ title: 'Could not add comment to case' });
        }

        await this.load();
    }

    private async onEdit(event: any): Promise<void> {
        const { error } = await updateCaseComment({
            path: { id: Number(this.caseId), commentId: event.detail.commentId },
            body: { comment: event.detail.value },
        });

        if (error) {
            return this.errorDialog.showError({ title: 'Could not edit comment' });
        }

        await this.load();
    }

    private async onReply(event: any): Promise<void> {
        const { error } = await addCaseComment({
            path: { id: Number(this.caseId) },
            body: { parentId: event.detail.parentId, comment: event.detail.value },
        });

        if (error) {
            return this.errorDialog.showError({ title: 'Could not reply to comment' });
        }

        await this.load();
    }

    private async onDelete(event: any): Promise<void> {
        const { error } = await archiveCaseComment({
            path: { id: Number(this.caseId), commentId: event.detail.value },
            parseAs: 'stream',
        });

        if (error) {
            return this.errorDialog.showError({ title: 'Could not delete comment' });
        }

        await this.load();
    }

    render(): TemplateResult {
        return html`
            <comments-area
                .comments="${this._comments}"
                @add="${this.onAdd}"
                @edit="${this.onEdit}"
                @reply="${this.onReply}"
                @delete="${this.onDelete}"
                .disabled="${this.disabled}"
            >
                <div class="grid-vertical gap-05">
                    <pli-text as="h4" variant="h4">Comments</pli-text>
                    <pli-text as="p">Share your notes and thought process</pli-text>
                </div>
            </comments-area>
        `;
    }
}
