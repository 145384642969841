import { LitElement, TemplateResult, css, html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import '../../pli/pli-card';
import '../../pli/pli-text';
import '../../pli/pli-table';
import '../../pli/pli-status-label';
import '../../pli/pli-user-bubble-list';
import { PliUserBubbleListProps } from '../../pli/pli-user-bubble-list';
import { defineHeaderItems } from '../../pli/pli-table';
import '../../pli/pli-enum-filter';
import { Task } from '@lit/task';
import { baseStyles, gridStyles } from 'pli';
import { consume } from '@lit/context';
import { StorageHandler } from 'storage-handler';
import '../../pli/pli-pagination';
import { PliStatusLabelVariant } from 'pli/pli-status-label';
import { dateTimeFormatter } from 'utils/datetime-formatter';
import '../../pli/pli-pager';
import { CaseStatus, getCaseList, GetCaseList_CaseResult } from 'tms-client';
import { errorDialogContext, ErrorDialogController } from 'context/error-dialog-context';

@customElement('cases-overview')
class CasesOverview extends LitElement {
    @consume({ context: errorDialogContext, subscribe: true })
    errorDialog: ErrorDialogController;

    headerItems = defineHeaderItems({
        Id: {
            sortField: null,
            columnSpan: 1,
        },
        Name: {
            sortField: null,
            columnSpan: 3,
        },
        Customer: {
            sortField: null,
            columnSpan: 2,
        },
        Status: {
            sortField: null,
            columnSpan: 2,
        },
        Created: {
            sortField: 'Created',
            columnSpan: 2,
        },
        Assigned: {
            sortField: null,
            columnSpan: 2,
        },
    });

    @state()
    _page = 1;

    @state()
    _sorrtField = null;

    @state()
    _sortOrder = null;

    @state()
    _total = 0;

    availableStatuses: { label: string; value: CaseStatus }[] = [
        { label: 'Investigating', value: 'Investigating' },
        { label: 'Resolved', value: 'Resolved' },
        { label: 'Reporting', value: 'Reporting' },
        { label: 'Reported', value: 'Reported' },
    ];

    @state()
    _statuses: CaseStatus[] = this.availableStatuses.map(({ value }) => value);

    static styles = [baseStyles, gridStyles, css``];

    sortOrderKey: string = 'case-list-order';
    items: GetCaseList_CaseResult[] = [];

    private _task = new Task(this, {
        task: async ([page, sortField, sortOrder, statuses]) => {
            const { error, data } = await getCaseList({
                query: {
                    page,
                    sortField,
                    sortOrder,
                    statuses,
                },
            });

            if (error) {
                return this.errorDialog.showError({ title: 'Could not get cases' });
            }

            this._total = data.total;
            this.items = data.list;
        },
        args: () => [this._page, this._sorrtField, this._sortOrder, this._statuses] as const,
    });

    connectedCallback(): void {
        super.connectedCallback();

        const stored = StorageHandler.getFromStorage(this.sortOrderKey);
        if (stored !== null) {
            this._sorrtField = stored.field;
            this._sortOrder = stored.sortOrder;
        }
    }

    onChange(event: CustomEvent) {
        this._statuses = event.detail.value.map(({ value }) => value);
    }

    private _paginationHandler(event: CustomEvent) {
        this._page = event.detail.page;
    }

    private _sort(event: CustomEvent) {
        this._sorrtField = event.detail.field;
        this._sortOrder = event.detail.order;
    }

    private _renderItem(item: GetCaseList_CaseResult): TemplateResult {
        return html`
            <tr>
                <td><strong>${item.caseId}</strong></td>
                <td>
                    <a data-link="navigate" href="customers/${item.customerId}/cases/${item.caseId}/#investigations"
                        >${item.name}</a
                    >
                </td>
                <td>${item.customer.name}</td>
                <td>
                    <pli-status-label variant="${item.status as PliStatusLabelVariant}"></pli-status-label>
                </td>
                <td>${dateTimeFormatter(item.created.toString())}</td>
                <td>
                    <pli-user-bubble-list
                        .items="${item.assigned as PliUserBubbleListProps['items']}"
                    ></pli-user-bubble-list>
                </td>
            </tr>
        `;
    }

    render() {
        return html`
            <div class="grid-vertical gap-1">
                <pli-card>
                    <div class="grid">
                        <div class="col-span-2">
                            <pli-enum-filter
                                @change="${this.onChange}"
                                placeholder="Status"
                                .items="${this.availableStatuses}"
                            ></pli-enum-filter>
                        </div>
                    </div>
                </pli-card>
                <pli-card>
                    <div class="grid">
                        <div class="col-span-8">
                            <pli-text variant="h2" as="h2">Cases</pli-text>
                        </div>
                    </div>
                    <pli-pager
                        .items="${this.items}"
                        page="${this._page}"
                        total="${this._total}"
                        @page-update="${this._paginationHandler}"
                    >
                        <pli-table
                            @sort="${this._sort}"
                            .headerItems="${this.headerItems}"
                            .items="${this.items}"
                            .renderTemplate="${this._renderItem}"
                            sortOrderKey="case-list-order"
                        >
                        </pli-table>
                    </pli-pager>
                </pli-card>
            </div>
        `;
    }
}
