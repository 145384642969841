import { bindable, bindingMode } from 'aurelia-framework';

const statuses = {
    Investigating: {
        color: 'investigating',
        text: 'Investigating',
    },
    Resolved: {
        color: 'resolved',
        text: 'Resolved',
    },
    Reporting: {
        color: 'reporting',
        text: 'Reporting',
    },
    Reported: {
        color: 'reported',
        text: 'Reported',
    },
};

export class CaseStatus {
    @bindable status: string;

    color: string;
    text: string;

    statusChanged(value: string) {
        if (value === undefined || value === null) {
            return;
        }

        let status = statuses[value];

        if (status) {
            this.color = status.color;
            this.text = status.text;
        }
    }
}
