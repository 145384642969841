import { LitElement, TemplateResult, html } from 'lit';
import { customElement, state, property } from 'lit/decorators.js';
import { consume } from '@lit/context';
import { Task } from '@lit/task';

import 'pli/pli-table';
import 'pli/pli-status-label';
import 'pli/pli-pagination';
import 'pli/pli-button';
import 'pli/pli-icon';
import 'shared/comments/comments-area';

import { defineHeaderItems } from 'pli/pli-table';
import { SortableOrder } from 'schema/pagination/pagination-schema';
import { StorageOrderController } from 'controllers/storage-order-controller';
import { getFormattedTime } from 'utils/datetime-formatter';
import { when } from 'lit/directives/when.js';
import { styles } from 'pli/styles';
import {
    addCaseAlert,
    getCaseAlertAttachedList,
    GetCaseAlertAttachedList_ItemResult,
    GetCaseAlertAttachedList_SortField,
    GetCaseAlertAttachedList_SortFieldSchema,
    GetCaseAlertAttachedListResponse,
    removeCaseAlert,
} from 'tms-client';
import { errorDialogContext, ErrorDialogController } from 'context/error-dialog-context';
import '../../../pli/pli-pager';
import './add-alert-to-case-dialog';
import { AddAlertToCaseEvent } from './add-alert-to-case-dialog';

@customElement('case-details-alerts')
class CaseDetailsAlerts extends LitElement {
    @property({ type: String, reflect: true })
    caseId?: string;

    @property({ type: Boolean, reflect: true })
    disabled?: boolean;

    @property({ type: String, reflect: true })
    customerId?: string;

    @consume({ context: errorDialogContext })
    errorDialog?: ErrorDialogController;

    @state()
    _total = 0;
    @state()
    _sortField: GetCaseAlertAttachedList_SortField | null = null;
    @state()
    _sortOrder: SortableOrder | null = null;
    @state()
    _alerts: GetCaseAlertAttachedListResponse['list'] = [];

    _sortOrderKey: string = 'case-alert-list-order';
    _controller = new StorageOrderController(this, this._sortOrderKey);

    static styles = [styles.flex, styles.grid];

    _headerItems = defineHeaderItems({
        Id: {
            sortField: GetCaseAlertAttachedList_SortFieldSchema.enum[1],
            columnSpan: 1,
        },
        Rule: {
            sortField: null,
            columnSpan: 1,
        },
        Status: {
            sortField: null,
            columnSpan: 2,
        },
        Created: {
            sortField: GetCaseAlertAttachedList_SortFieldSchema.enum[0],
            columnSpan: 4,
        },
    });

    _task = new Task(this, {
        task: async ([caseId, sortField]) => {
            const { data, error } = await getCaseAlertAttachedList({
                path: { id: Number(caseId) },
                query: { sortField: sortField as GetCaseAlertAttachedList_SortField },
            });

            if (error) {
                return this.errorDialog.showError({ title: 'Could not get attached alerts' });
            }

            this._alerts = data.list;
            this._total = data.total;
        },
        args: () => [this.caseId, this._controller.sortField, this._controller.sortOrder, this.disabled] as const,
    });

    private _sort(event: CustomEvent) {
        this._controller.sortField = event.detail.field;
        this._controller.sortOrder = event.detail.order;
    }

    async onAddAlerts(event: AddAlertToCaseEvent) {
        const { value } = event.detail;
        for (const alertId of value) {
            await addCaseAlert({
                path: { id: Number(this.caseId) },
                body: { alertId },
                parseAs: 'stream',
            });
        }
        this._task.run();
    }

    private async removeAlert(alertId: number): Promise<void> {
        if (this._alerts.length == 1) {
            return;
        }

        const { error } = await removeCaseAlert({
            path: { id: Number(this.caseId), alertId: alertId },
            parseAs: 'stream',
        });

        if (error) {
            return this.errorDialog.showError({ title: 'Could not remove alert from case' });
        }

        this._task.run();
    }

    render(): TemplateResult {
        return html`
            <pli-card>
                <div class="flex justify-between items-center">
                    <pli-text variant="h4" as="h4">Attached Alerts</pli-text>
                    ${this._task.render({
                        complete: () => html`
                            ${when(
                                !this.disabled,
                                () =>
                                    html`<add-alert-to-case-dialog
                                        caseId="${this.caseId}"
                                        customerId="${this.customerId}"
                                        .disabled="${this.disabled}"
                                        @add-alerts="${this.onAddAlerts}"
                                    ></add-alert-to-case-dialog>`,
                            )}
                        `,
                    })}
                </div>
                ${this._task.render({
                    pending: () =>
                        html`<pli-table
                            .loading="${true}"
                            .headerItems="${this._headerItems}"
                            loadingRowsCount="${10}"
                        ></pli-table>`,
                    complete: () => this.renderResult(),
                })}
            </pli-card>
        `;
    }

    private renderResult(): TemplateResult {
        const renderItem = (alert: GetCaseAlertAttachedList_ItemResult): TemplateResult => html`
            <tr>
                <td>
                    <a data-link="navigate" href="customers/${this.customerId}/alerts/${alert.alertId}/#investigations">
                        <strong>${alert.alertId}</strong>
                    </a>
                </td>
                <td>${alert.rule.name}</td>
                <td><pli-status-label variant="${alert.status}"></pli-status-label></td>
                <td>
                    <div class="flex justify-between items-center">
                        ${getFormattedTime(new Date(alert.created))}
                        ${when(
                            !this.disabled,
                            () =>
                                html`<pli-button
                                    data-link="prevent"
                                    size="lg"
                                    width="hug-content"
                                    variant="destructive"
                                    .onClick="${() => this.removeAlert(alert.alertId)}"
                                    .disabled="${this._alerts.length === 1}"
                                >
                                    <pli-icon slot="icon-left" name="trash"></pli-icon>
                                    Remove
                                </pli-button>`,
                        )}
                    </div>
                </td>
            </tr>
        `;
        return html`
            <div class="grid-vertical gap-2">
                <pli-table
                    @sort="${this._sort}"
                    .headerItems="${this._headerItems}"
                    .items="${this._alerts}"
                    .renderTemplate="${renderItem}"
                    sortOrderKey="${this._sortOrderKey}"
                >
                </pli-table>
            </div>
        `;
    }
}
