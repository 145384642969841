import { z } from 'zod';

const _requiredStringValue = (message: string = 'Required field') =>
    z.string({ invalid_type_error: message }).min(1, { message });

const docSchema = z.object({
    name: _requiredStringValue(),
});

export type CaseDetailsDocument = z.infer<typeof docSchema>;

export const mainSchema = z.object({
    name: _requiredStringValue('Name is required'),
});

export const keys = mainSchema.keyof();

export type SchemaKey = z.infer<typeof keys>;
export type Payload = z.infer<typeof mainSchema>;
